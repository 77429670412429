import Vue from "vue";
import {
  SET_IS_LOADING,
  SET_ENTITIES,
  SET_IS_INITIALIZED,
  SET_FILTER,
  SET_IS_FETCHABLE
} from "./mutation-types";

export default {
  [SET_ENTITIES](state, dataPoints) {
    Vue.set(state, "dataPoints", dataPoints);
  },
  [SET_IS_LOADING](state, isLoading) {
    state.isLoading = isLoading;
  },
  [SET_IS_INITIALIZED](state) {
    state.isInitialized = true;
  },
  [SET_FILTER](state, { property, value }) {
    Vue.set(state.filters, property, value);
  },
  [SET_IS_FETCHABLE](state, isFetchable) {
    state.fetchable = isFetchable;
  }
};
